import React from 'react';

const DashboardCard = ({ title, value }) => (
  <div className="bg-white p-4 rounded-lg shadow-md">
    <h3 className="text-lg font-semibold text-gray-700 mb-2">{title}</h3>
    <p className="text-3xl font-bold text-blue-500">{value}</p>
  </div>
);

export default DashboardCard;
