import { jwtDecode } from "jwt-decode";

export const isTokenValid = () => {
  const token = localStorage.getItem('token');
  if (!token) return false;

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Em segundos
    if (decodedToken.exp > currentTime) {
      return true;
    } else {
      // Token expirado, remover do localStorage
      localStorage.removeItem('token');
      return false;
    }
  } catch (error) {
    // Token inválido, remover do localStorage
    localStorage.removeItem('token');
    return false;
  }
};
