import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import DashboardCard from '../components/DashboardCard';
import ClientItem from '../components/ClientItem';
import AdAccountList from '../components/AdAccountList';
import CampaignList from '../components/CampaignList'; 

const HomePage = () => {
  const [activeMenu, setActiveMenu] = useState('Dashboard');
  const user = { name: 'John Doe' };

  const renderContent = () => {
    switch (activeMenu) {
      case 'Dashboard':
        return (
          <div className="p-6">
            <h2 className="text-2xl font-semibold mb-4">Dashboard Overview</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <DashboardCard title="Active Campaigns" value="12" />
              <DashboardCard title="Total Clients" value="45" />
              <DashboardCard title="Monthly Revenue" value="$25,000" />
            </div>
          </div>
        );
      case 'Ad Accounts' : 
      return (
        <div className="p-6">
        <h2 className="text-2xl font-semibold mb-4">Contas de Anúncio</h2>
        <div className="space-y-4">
          <AdAccountList></AdAccountList>
        </div>
      </div>
      );
      case 'Campaigns':
        return (
          <div className="p-6">
            <h2 className="text-2xl font-semibold mb-4">Campaigns</h2>
            <div className="space-y-4">
            <CampaignList></CampaignList>
            </div>
          </div>
        );
        case 'Clients':
          return (
            <div className="p-6">
              <h2 className="text-2xl font-semibold mb-4">Clientes</h2>
              <ClientItem /> {/* Aqui chamamos o componente que busca os clientes da API */}
            </div>
          );
        
      case 'Finances':
        return (
          <div className="p-6">
            <h2 className="text-2xl font-semibold mb-4">Finances</h2>
            <div className="bg-white p-4 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold mb-2">Financial Overview</h3>
              <div className="h-64 bg-gray-200 rounded-md mb-4"></div>
              <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors">
                Export Report
              </button>
            </div>
          </div>
        );
      default:
        return <div className="p-6">Content for {activeMenu}</div>;
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar activeMenu={activeMenu} setActiveMenu={setActiveMenu} user={user} />
      <main className="flex-1 overflow-y-auto">{renderContent()}</main>
    </div>
  );
};

export default HomePage;
