import React from 'react';

const CampaignItem = ({ name, status }) => (
  <div className="flex items-center justify-between bg-white p-4 rounded-lg shadow-md">
    <span className="font-semibold">{name}</span>
    <span
      className={`px-2 py-1 rounded-full text-xs font-semibold ${
        status === 'active'
          ? 'bg-green-200 text-green-800'
          : status === 'upcoming'
          ? 'bg-yellow-200 text-yellow-800'
          : 'bg-gray-200 text-gray-800'
      }`}
    >
      {status}
    </span>
  </div>
);

export default CampaignItem;
