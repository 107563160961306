import React, { useState, useEffect } from 'react';
import AddClient from './AddClient';
import { useNavigate } from 'react-router-dom';

const ClientList = () => {
  const [clients, setClients] = useState([]); // Armazena os clientes da API
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento
  const [error, setError] = useState(null); // Estado para armazenar erros
  const [showCreateClient, setShowCreateClient] = useState(false);
  const navigate = useNavigate();

  // Função para buscar clientes da API
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = localStorage.getItem('token');
  
        const response = await fetch('https://testgapi-production.up.railway.app/clients/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setClients(data);
          setLoading(false);
        } else if (response.status === 401) {
          console.error('Acesso não autorizado. Redirecionando para o login.');
          localStorage.removeItem('token');
          navigate('/login');
        } else {
          console.error('Erro ao buscar clientes:', response.statusText);
          setError('Erro ao buscar clientes');
          setLoading(false);
        }
      } catch (err) {
        console.error('Erro ao buscar clientes:', err);
        setError('Erro ao buscar clientes');
        setLoading(false);
      }
    };
  
    fetchClients();
  }, [navigate]);
  
  // Função para alternar o formulário de criar cliente
  const openCreateAdAccount = () => {
    setShowCreateClient(true);
  };

  if (showCreateClient) {
    return <AddClient />;
  }

  if (loading) {
    return <p>Carregando clientes...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-md"> {/* Div pai que envolve todas as divs de clientes */}
      <h2 className="text-2xl font-semibold mb-4">Lista de Clientes</h2>
      
      {clients.length > 0 ? (
        clients.map((client) => (
          <div
            key={client.id}
            className="flex items-center justify-between bg-white p-4 rounded-lg shadow-md mb-4 border border-gray-300"
          >
            <div>
              <h3 className="font-semibold text-gray-800">{client.clientName}</h3> {/* Nome do cliente */}
              <p className="text-sm text-gray-600">{client.clientEmail}</p> {/* Email do cliente */}
            </div>
            <button className="text-blue-500 hover:text-blue-600">Ver Detalhes</button>
          </div>
        ))
      ) : (
        <p>Nenhum cliente encontrado.</p>
      )}

      <div className="flex justify-center">
        <button
          onClick={openCreateAdAccount}
          className="mt-10 px-4 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors"
        >
          Adicionar novo cliente
        </button>
      </div>
    </div>
  );
};

export default ClientList;
