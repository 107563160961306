import React, { useEffect, useState, useCallback } from 'react';

const FacebookLogin = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // Função para verificar o status de login
  const handleLoginStatus = useCallback(() => {
    if (window.FB) {
      console.log("Verificando status de login do Facebook...");
      window.FB.getLoginStatus(function (response) {
        console.log("Resposta de getLoginStatus:", response);
        statusChangeCallback(response);
      });
    } else {
      console.log("FB não foi carregado corretamente.");
    }
  }, []);

  // Função de callback para mudanças de status
  const statusChangeCallback = (response) => {
    console.log("Callback de mudança de status:", response);
    if (response.status === 'connected') {
      // Se o usuário está logado no Facebook
      console.log("Usuário está conectado ao Facebook.");
      setIsLoggedIn(true);
      sendTokenToBackend(response.authResponse.accessToken);
    } else {
      // Se o usuário não está logado
      console.log("Usuário não está logado no Facebook.");
      setIsLoggedIn(false);
      setShowModal(true);
    }
  };

  // Função para enviar o token de acesso ao backend
  const sendTokenToBackend = async (token) => {
    try {
      console.log("Enviando token para o backend:", token);
      await fetch('https://testgapi-production.up.railway.app/users/1', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: token,
      });
    } catch (error) {
      console.error('Erro ao enviar o token para o backend:', error);
    }
  };

  // Função para iniciar o login do Facebook
  const loginWithFacebook = () => {
    if (window.FB) {
      window.FB.login(function (response) {
        console.log("Resposta de login:", response);
        if (response.authResponse) {
          sendTokenToBackend(response.authResponse.accessToken);
        } else {
          console.log("Login não autorizado ou erro.");
        }
      }, { scope: 'public_profile,email' });
    }
  };

  // Carregar o SDK do Facebook
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '1008426500498419',
        cookie: true,
        xfbml: true,
        version: 'v13.0',
      });

      // Verifica o status de login assim que o SDK for carregado
      handleLoginStatus();
    };

    // Carrega o SDK do Facebook de forma assíncrona
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, [handleLoginStatus]);

  // Mostrar modal se o usuário não estiver logado
  return (
    <>
      {showModal && (
        <div className="modal bg-gray-700 p-6 rounded-lg shadow-lg">
          <h3 className="text-lg text-white mb-4">
            Ops! Parece que você não está logado ao Facebook!
          </h3>
          <p className="text-white mb-4">
            Nossa plataforma funciona melhor se você estiver logado!
          </p>
          <button
            onClick={loginWithFacebook}
            className="bg-blue-600 text-white px-4 py-2 rounded-lg"
          >
            Login com Facebook
          </button>
        </div>
      )}
    </>
  );
};

export default FacebookLogin;
