import React, { useState } from 'react';
import {
  FiHome,
  FiUsers,
  FiDollarSign,
  FiFileText,
  FiSettings,
  FiChevronLeft,
  FiChevronRight,
  FiLogOut,
} from 'react-icons/fi';
import { BsGraphUp } from 'react-icons/bs';
import { MdOutlineSwitchAccount } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ activeMenu, setActiveMenu, user }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();

  const menuItems = [
    { name: 'Dashboard', icon: FiHome },
    { name: 'Ad Accounts', icon: MdOutlineSwitchAccount },
    { name: 'Campaigns', icon: BsGraphUp },
    { name: 'Clients', icon: FiUsers },
    { name: 'Finances', icon: FiDollarSign },
    { name: 'Reports', icon: FiFileText },
    { name: 'Settings', icon: FiSettings },
  ];

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/index.js');
  };

  return (
    <aside
      className={`bg-white shadow-md transition-all duration-300 flex flex-col ${
        isCollapsed ? 'w-20' : 'w-64'
      }`}
    >
      {/* Botão da setinha para expandir/recolher */}
      <div className="p-2 flex justify-end">
        <button
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="p-1 focus:outline-none"
        >
          {isCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
        </button>
      </div>

      {/* Conteúdo da sidebar */}
      <div className="flex-1 flex flex-col justify-between">
        {/* Menu e saudação */}
        <div>
          <div className={`p-4 ${isCollapsed ? 'hidden' : 'block'}`}>
            <h1 className="text-2xl font-bold text-gray-800 mb-4">Adsman</h1>
            <p className="text-gray-600 mb-6">Hello, {user.name}</p>
          </div>
          <nav className="flex-1">
            <ul className="space-y-2">
              {menuItems.map((item) => (
                <li key={item.name}>
                  <button
                    onClick={() => setActiveMenu(item.name)}
                    className={`flex items-center w-full p-2 rounded-md transition-colors ${
                      activeMenu === item.name
                        ? 'bg-blue-500 text-white'
                        : 'text-gray-600 hover:bg-gray-100'
                    }`}
                  >
                    <item.icon className="mr-3" />
                    {!isCollapsed && item.name}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        {/* Botão de Logout */}
        <div className="p-4">
          <button
            onClick={handleLogout}
            className="flex items-center w-full p-2 rounded-md text-gray-600 hover:bg-gray-100"
          >
            <FiLogOut className="mr-3" />
            {!isCollapsed && 'Logout'}
          </button>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
