import React from 'react';
import { BrowserRouter as Router, Route, Routes , Navigate } from 'react-router-dom';
import AdsManLanding from './components/AdsManLanding';
import HomePage from './pages/HomePage';
import Login from './components/Login';
import Register from './components/Register';
import FacebookLogin from './components/FacebookLogin'; // Importando o middleware de login
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <Router>
      <Routes>

        <Route path="/" element={<AdsManLanding />} />
        {/*ROTAS PUBLICAS*/}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/*ROTAS PROTEGIDAS*/}
        <Route element={<PrivateRoute />}>
          <Route path="/home" element={
          <>
            <FacebookLogin />
            <HomePage />
          </>
        } />
          {/* Outras rotas protegidas podem ser adicionadas aqui */}
        </Route>
                {/* Rota Padrão para Rotas Não Encontradas */}
                <Route path="*" element={<Navigate to="/" />} />

      </Routes>
    </Router>
  );
}

export default App;
