import React from 'react';
import { FaPlayCircle, FaCheck, FaRocket } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { isTokenValid } from '../utils/auth';

const AdsManLanding = () => {
  const features = [
    'Advanced analytics dashboard',
    'AI-powered ad optimization',
    'Real-time performance tracking',
    'Multi-platform integration',
    'Customizable reporting',
  ];

  const navigate = useNavigate();

  const handleLoginClick = () => {
    if (isTokenValid()) {
      navigate('/home');
    } else {
      navigate('/login');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white">
      <header className="container mx-auto px-4 py-8">
        <div className="flex items-center justify-between">
          <h1 className="text-4xl font-bold text-blue-600">AdsMan</h1>
          <nav>
            <ul className="flex items-center space-x-6"> {/* Ajustado para alinhar */}
              <li>
              <button
                  onClick={handleLoginClick}
                  className="text-gray-600 hover:text-blue-600 focus:outline-none"
                >
                  Login
                </button>
              </li>
              <li>
                <Link to="/register">
                  <button className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300">
                    Cadastre-se
                  </button>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <main className="container mx-auto px-4 py-16">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="lg:w-1/2 mb-12 lg:mb-0 pr-10"> {/* Adicionado padding-right para espaçar o texto */}
            <h2 className="text-5xl font-bold text-gray-800 mb-6">Gerencie seus anúncios com precisão</h2>
            <p className="text-xl text-gray-600 mb-8">AdsMan é a plataforma onde você gerencia anúncios e recolhe insights e gráficos de campanhas automáticamente</p>
            <div className="flex space-x-4">
              <button className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition duration-300 flex items-center">
                <FaRocket className="mr-2" /> Começar a Usar
              </button>
              <button className="bg-white text-blue-600 px-6 py-3 rounded-lg border border-blue-600 hover:bg-blue-50 transition duration-300 flex items-center">
                <FaPlayCircle className="mr-2" /> Watch Demo
              </button>
            </div>
          </div>
          <div className="lg:w-1/2">
            <div className="relative" style={{ paddingBottom: '56.25%', height: 0 }}>
              <iframe
                className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
                src="https://www.youtube.com/embed/dQw4w9WgXcQ"
                title="AdsMan Demo Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>

        <section className="mt-24">
          <h3 className="text-3xl font-bold text-center text-gray-800 mb-12">Principais funcionalidades</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
                <FaCheck className="text-green-500 mb-4 text-2xl" />
                <h4 className="text-xl font-semibold text-gray-800 mb-2">{feature}</h4>
                <p className="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
            ))}
          </div>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-8 mt-24">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p>&copy; 2023 AdsMan. All rights reserved.</p>
            <div className="flex space-x-4 mt-4 md:mt-0">
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AdsManLanding;
