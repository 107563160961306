import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AddClient = ({ onBack }) => {
  const [clientName, setClientName] = useState('');
  const [clientNumber, setClientNumber] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const data = {
      clientName,
      clientEmail,
      clientNumber,
    };
  
    try {
      const token = localStorage.getItem('token');
  
      const response = await fetch('https://testgapi-production.up.railway.app/clients/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        setSuccessMessage('Cliente adicionado com sucesso!');
        setTimeout(() => {
          setSuccessMessage('');
          onBack(); // Volta para a lista de clientes
        }, 3000);
      } else if (response.status === 401) {
        console.error('Acesso não autorizado. Redirecionando para o login.');
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        console.error('Erro ao adicionar cliente:', response.statusText);
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
  };
  

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-4">
        <button onClick={onBack} className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400">
          Voltar
        </button>
        <h2 className="text-xl font-semibold text-gray-800 text-center flex-1">Adicionar cliente</h2>
      </div>
      
      {successMessage && (
        <div className="mb-4 p-4 text-white bg-green-500 rounded-lg">
          {successMessage}
        </div>
      )}

      <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
        <input
          className="border border-indigo-500 rounded-lg p-2"
          placeholder="Nome do cliente"
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
        />
        <input
          className="border border-indigo-500 rounded-lg p-2"
          placeholder="Número de contato"
          value={clientNumber}
          onChange={(e) => setClientNumber(e.target.value)}
        />
        <input
          className="border border-indigo-500 rounded-lg p-2"
          placeholder="Email"
          value={clientEmail}
          onChange={(e) => setClientEmail(e.target.value)}
        />
        <button
          type="submit"
          className="px-4 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors"
        >
          Salvar Cliente
        </button>
      </form>
    </div>
  );
};

export default AddClient;
