import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AdAccount = ({ onBack }) => {
  const [accountId, setAccountId] = useState('');
  const [clientId, setClientId] = useState(''); // Armazena o ID do cliente
  const [nickname, setNickname] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [clients, setClients] = useState([]); // Estado para armazenar a lista de clientes
  const navigate = useNavigate();

  // Função para buscar os clientes do backend
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = localStorage.getItem('token');
  
        const response = await fetch('https://testgapi-production.up.railway.app/clients/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const clientsData = await response.json();
          setClients(clientsData);
          console.log('Clientes ===> ', clientsData);
        } else if (response.status === 401) {
          console.error('Acesso não autorizado. Redirecionando para o login.');
          localStorage.removeItem('token');
          navigate('/login');
        } else {
          console.error('Erro ao buscar os clientes:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao buscar os clientes:', error);
      }
    };
  
    fetchClients();
  }, [navigate]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const data = {
      accountId,
      nickname,
    };
  
    try {
      const token = localStorage.getItem('token');
  
      const response = await fetch(`https://testgapi-production.up.railway.app/ad-accounts/?clientId=${clientId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        setSuccessMessage('Conta de anúncio adicionada com sucesso!');
        setTimeout(() => {
          setSuccessMessage('');
          onBack(); // Voltar para a lista de contas após o sucesso
        }, 3000);
      } else if (response.status === 401) {
        console.error('Acesso não autorizado. Redirecionando para o login.');
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        console.error('Erro ao adicionar a conta de anúncio:', response.statusText);
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
  };
  
  
  

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-4">
        <button onClick={onBack} className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400">
          Voltar
        </button>
        <h2 className="text-xl font-semibold text-gray-800 text-center flex-1">Adicionar conta de anúncio</h2>
      </div>
      
      {successMessage && (
        <div className="mb-4 p-4 text-white bg-green-500 rounded-lg">
          {successMessage}
        </div>
      )}

      <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
        <input
          className="border border-indigo-500 rounded-lg p-2"
          placeholder="ID da Conta de Anúncio"
          value={accountId}
          onChange={(e) => setAccountId(e.target.value)}
        />
        <select
          className="border border-indigo-500 rounded-lg p-2"
          value={clientId} // Alterado para clientId
          onChange={(e) => setClientId(e.target.value)}
        >
          <option value="">Selecione um cliente</option>
          {clients.map((client) => (
            <option key={client.clientId} value={client.clientId}>
              {client.clientName}
            </option>
          ))}
        </select>
        <input
          className="border border-indigo-500 rounded-lg p-2"
          placeholder="Apelido da Conta"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
        />
        <button
          type="submit"
          className="px-4 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors"
        >
          Salvar Conta
        </button>
      </form>
    </div>
  );
};

export default AdAccount;
