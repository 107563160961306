import React, { useEffect, useState } from 'react';
import CampaignItem from './CampaignItem'; // Importando o componente para exibir campanhas

const CampaignList = () => {
  const [campaigns, setCampaigns] = useState([]); // Estado para armazenar as campanhas
  const [loading, setLoading] = useState(true);   // Estado para mostrar o carregamento
  const [error, setError] = useState(null);       // Estado para erros

  useEffect(() => {
    // Função para buscar campanhas do backend
    const fetchCampaigns = async () => {
      try {
        const response = await fetch('https://testgapi-production.up.railway.app/campaigns/650977378765984'); // Coloque aqui o ID correto da conta
        const data = await response.json();
        setCampaigns(data); // Armazenando as campanhas
        setLoading(false);  // Carregamento finalizado
      } catch (error) {
        console.error('Erro ao buscar campanhas:', error);
        setError('Erro ao buscar campanhas.');
        setLoading(false);
      }
    };

    fetchCampaigns(); // Chamar a função para buscar as campanhas ao carregar o componente
  }, []);

  if (loading) {
    return <p>Carregando campanhas...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4">Campaigns</h2>
      <div className="space-y-4">
        {campaigns.length > 0 ? (
          campaigns.map((campaign) => (
            <CampaignItem key={campaign.id} name={campaign.name} status={campaign.status} />
          ))
        ) : (
          <p>Nenhuma campanha encontrada.</p>
        )}
      </div>
    </div>
  );
};

export default CampaignList;
