import React, { useState, useEffect } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Ícones para expandir/colapsar
import AdAccount from '../components/AdAccount';
import { useNavigate } from 'react-router-dom';

const AdAccountList = () => {
  const [adAccounts, setAdAccounts] = useState([]); // Estado para armazenar as contas de anúncio
  const [expandedAccounts, setExpandedAccounts] = useState({}); // Estado para controlar as divs expandidas
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [clientNames, setClientNames] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdAccounts = async () => {
      try {
        const token = localStorage.getItem('token');
  
        const response = await fetch('https://testgapi-production.up.railway.app/ad-accounts/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setAdAccounts(data);
  
          const uniqueClientIds = [...new Set(data.map(account => account.clientID))];
          uniqueClientIds.forEach(clientId => {
            fetchClientName(clientId);
          });
        } else if (response.status === 401) {
          // Token inválido ou expirado
          console.error('Acesso não autorizado. Redirecionando para o login.');
          // Redirecionar para a página de login ou lidar com a autenticação
        } else {
          console.error('Erro ao buscar contas de anúncio:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao buscar contas de anúncio:', error);
      }
    };
  
    fetchAdAccounts();
  }, []);
  
  const fetchClientName = async (clientID) => {
    if (!clientID || clientNames[clientID]) return;
  
    try {
      const token = localStorage.getItem('token');
  
      const response = await fetch(`https://testgapi-production.up.railway.app/clients/${clientID}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setClientNames((prevState) => ({
          ...prevState,
          [clientID]: data.clientName,
        }));
      } else if (response.status === 401) {
        console.error('Acesso não autorizado ao buscar cliente. Redirecionando para o login.');
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        console.error(`Erro ao buscar cliente ${clientID}:`, response.statusText);
      }
    } catch (error) {
      console.error(`Erro ao buscar cliente ${clientID}:`, error);
    }
  };
  

  // Função para alternar a criação de nova conta de anúncio
  const openCreateAdAccount = () => {
    setShowCreateAccount(true);
  };

  // Função para alternar o estado de expandir/colapsar para cada conta
  const toggleExpand = (accountId) => {
    setExpandedAccounts((prevState) => ({
      ...prevState,
      [accountId]: !prevState[accountId],
    }));
  };

  if (showCreateAccount) {
    return <AdAccount />;
  }

  return (
    <div className="flex flex-col bg-white p-6 rounded-lg shadow-md space-y-4">
{adAccounts.length > 0 ? (
  adAccounts.map((account) => (
    <div key={account.accountId} className="bg-white p-4 rounded-lg shadow-md border border-gray-300 mb-4">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="font-semibold text-gray-800">{account.nickname}</h3>
        </div>
        <button
          onClick={() => toggleExpand(account.accountId)}
          className="text-gray-500 hover:text-gray-700"
        >
          {expandedAccounts[account.accountId] ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </div>
      {expandedAccounts[account.accountId] && (
        <div className="mt-2 p-2 bg-gray-50 rounded-lg">
          <p className="text-sm text-gray-700">ID da Conta: {account.accountId}</p>
          <p className="text-sm text-gray-600">
            Cliente: {clientNames[account.clientID] || 'Carregando...'}
          </p>
          <button
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            onClick={() => console.log('Editar conta', account.accountId)}
          >
            Editar
          </button>
        </div>
      )}
    </div>
  ))
) : (
  <p>Nenhuma conta de anúncio encontrada.</p>
)}


      <div className="flex justify-center">
        <button
          onClick={openCreateAdAccount}
          className="mt-10 px-4 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors w-80"
        >
          Adicionar nova conta de anúncio
        </button>
      </div>
    </div>
  );
};

export default AdAccountList;
